/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowBack, Settings, Support } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { isDesktop } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BrandLogo } from '../../Assets/brandLogo.svg';
import { ReactComponent as PoweredByTrential } from '../../Assets/poweredByTrential.svg';
import { UIStore, logout } from '../../Config/store';
import { MAX_WIDTH } from '../../Utils/enums';
import { sampleAppConfig } from '../SampleApp/config';
const drawerWidth = 240;
const screensToshowPoweredByTrential = ['/checkin', '/oauth/authorize']

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Base({ children }) {
    const theme = useTheme();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', maxWidth: MAX_WIDTH + 'px', margin: 'auto', width: "100%", justifyContent: 'center' }}>
            <CssBaseline />
            <div position="fixed" color="white" open={open} sx={{
                width: MAX_WIDTH + 'px',
                margin: 'auto', left: '0', right: '0', padding: '1em'
            }}>



            </div>


            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {['General Settings', 'Support'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <Settings /> : <Support />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Main open={open} style={{ padding: '0', textAlign: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <DrawerHeader />

                <span style={{ flex: 'auto' }}>{children}</span>
                {screensToshowPoweredByTrential.includes(location.pathname) ? <span
                    style={{ margin: 'auto', marginBottom: '0.5em' }}
                ><PoweredByTrential /></span> : null}

            </Main>
        </Box>
    );
}

export function SecondaryTopBar({ children, showFooter = true }) {
    const navigate = useNavigate()
    const currentUserData = UIStore.useState(s => s.currentUserData)
    const trentialId = UIStore.useState(s => s.trentialId)
    return (
        <>

            {currentUserData?.name ? <Typography variant="h6" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}><IconButton
                onClick={() => {
                    if (window.location.pathname.includes('/oauth/authorize')) {
                        logout()
                    }
                    navigate(-1)
                }}
            ><ArrowBack /></IconButton>Verify Documents</Typography> :
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: '1em' }}
                >
                    <Grid item xs={4}>
                        <a onClick={() => {
                            if (window.location.pathname.includes('/oauth/authorize')) {
                                logout()
                            }
                            navigate(-1)
                        }}
                        ><BrandLogo /></a>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="body1" sx={{ fontWeight: 700, textAlign: 'left', fontSize: '0.8rem' }}>
                            {sampleAppConfig.fullName}
                        </Typography>
                    </Grid>
                </Grid>}
            {/* <Outlet /> */}
            {children}
            {showFooter && <div
                style={{
                    padding: '1em 0',
                    position: 'fixed',
                    bottom: 0,
                    left: isDesktop ? 'auto' : 0,
                    right: isDesktop ? 'auto' : 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px -4px 12px 0px #0000000D',
                    width: MAX_WIDTH,
                    backgroundColor: '#ffffff'
                }}>
                <PoweredByTrential />
            </div >}
        </>
    )
}
