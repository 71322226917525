import { Close } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './App.css';
import { UIStore, fetchFromStore, rehydrateStore } from './Config/store';
import Auth from './Containers/Auth';
import { SecondaryTopBar } from './Containers/Base';
import CheckedIn from './Containers/Checkin/checkedIn';
import Digilocker from './Containers/Checkin/digilocker';
import DisplayInfo from './Containers/Checkin/displayInfo';
import Payment from './Containers/Checkin/payment';
import Scan from './Containers/Checkin/scan';
import DocsFlow from './Containers/DocsFlow';
import Home from './Containers/Home';
import Liveness from './Containers/Liveness';
import SampleApp from './Containers/SampleApp';
import { MAX_WIDTH } from './Utils/enums';
import { startRefreshAccessTokenAtRegularInterval } from './Utils/tokenUtils';

export const oauthLink = () => {
  const uid = uuid();
  return `/oauth/authorize?response_type=code&client_id=test1&scope=presentation:passport:read+presentation:voter-id:read+presentation:aadhaar:read+presentation:driving-license:read+presentation:pan:read+presentation:liveness:read&redirect_uri=`
    + process.env.REACT_APP_REDIRECT_URI + '&state=' + uid

}
export default function App() {
  const isAuthenticated = UIStore.useState(s => s.isAuthenticated);

  const [paymentDone, setPaymentDone] = useState(false)
  useEffect(() => {

    var r = document.querySelector(':root');
    r.style.setProperty('--MAX_WIDTH', MAX_WIDTH + "px");

    rehydrateStore()
    window.snackbar = (variant, msg) => {
      //console.log(msg);
      if (msg) {
        console.info('in snackbar')
        const key = enqueueSnackbar((
          <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: '100%', }}>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ width: '100%', }}>
              <Typography variant="body1"
                sx={{ width: '100%', position: 'relative' }}>
                {msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1))}
              </Typography>
            </Grid>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
              sx={{ position: 'absolute', top: '0px', right: '0px' }}
            >
              <Close fontSize="small" />
            </IconButton>


          </Grid>),
          {
            variant,
            style: { overflowWrap: "anywhere", zIndex: "9999999 !important" },
            className: "snackbar",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            // action: (key) => (
            //   <IconButton
            //     sx={{ display: 'none' }}
            //     size="small"
            //     aria-label="close"
            //     color="inherit"
            //     onClick={() => closeSnackbar(key)}
            //   >
            //     <Close fontSize="small" />
            //   </IconButton>
            // ),
          }
        );
      }
    };
    console.log('fetchFromStore(isAuthenticated)', fetchFromStore('isAuthenticated'))
    if (fetchFromStore('isAuthenticated')) {
      startRefreshAccessTokenAtRegularInterval()
    }
    // updateStore('credentialLivenessCheckRecord', {})

  }, [])

  console.log('isAuthenticated', isAuthenticated)
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/checkin/digilocker" element={<Digilocker />} />{
          isAuthenticated ? (
            <>

              <Route index path="/receive-creds" element={<SampleApp />} />
              <Route exact path="/checkin/scan" element={<Scan />} />
              <Route exact path="/checkin/displayInfo" element={<DisplayInfo />} />
              <Route exact path="/checkedIn/payment" element={<Payment paymentDone={paymentDone} setPaymentDone={setPaymentDone} />} />
              <Route exact path="/checkedIn" element={<CheckedIn setPaymentDone={setPaymentDone} />} />

              <Route exact path="/liveness" element={<Liveness />} />

              <Route exact path="/docs" element={<DocsFlow />} />

              <Route index exact path="/oauth/authorize" element={<SecondaryTopBar showFooter={false}><Home /></SecondaryTopBar>} />
              <Route path="/authorize" element={<Navigate to='/oauth/authorize' />} />
              <Route path="*" element={<Navigate to={oauthLink()} />} />
            </>
          ) : (
            <>
              <Route path="/oauth/authorize" element={<SecondaryTopBar><Auth /></SecondaryTopBar>} />
              <Route path="/authorize" element={<Navigate to='/oauth/authorize' />} />
              <Route index path="/receive-creds" element={<SampleApp />} />
              <Route path="*" element={<Navigate to='/receive-creds' />} />
            </>

          )
        }</Routes>

    </BrowserRouter>
  );
}


