import { Backdrop, Button, CircularProgress, FormControl, FormHelperText, Grid, Link, OutlinedInput, Typography } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import aadhaarSvg from '../../Assets/aadhaar.svg'
import { aadhaarOtpCall, aadharCall, getCredentials, getWallet } from '../../Config/api'
import { UIStore } from '../../Config/store'
// const getDots = (text) => {
//     let t = '';
//     for (let i = 4 - text.length; i > 0; i--) {
//         t += '•'
//     }
//     return t;
// }

export default function Aadhaar() {
  const navigate = useNavigate();
  const credentials = UIStore.useState(s => s.credentials)
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const submitOtpRef = useRef(null)
  const [currentScreen, setCurrentScreen] = useState('.')
  const [aadharNumber1, setAadharNumber1] = useState('')
  const [aadharNumber2, setAadharNumber2] = useState('')
  const [aadharNumber3, setAadharNumber3] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [otpInput, setOtpInput] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setCurrentScreen('loading')
    getWallet().then(async z => {
      if (z) {
        getCredentials().then(res => {
          if (res) {
            setCurrentScreen('existingAccount')
          } else {
            setCurrentScreen('.')
          }
        })
      }
    })

  }, [])


  const debouncedAadharCall = useCallback(debounce(async (val) => {
    setLoading(true)
    aadharCall(val)
      .then(x => {
        if (x) {
          setOtpSent(x)
        }
      }).finally(() => {
        setLoading(false)
      })
  }, 1000), [aadharNumber1, aadharNumber2, aadharNumber3]);


  return (
    <Grid container sx={{ padding: '0 1em' }}>
      <Typography variant="body1" sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '0.8rem',
        margin: '1em 0'
      }}>Enter Aadhaar Number to Continue

        <img src={aadhaarSvg} style={{ height: '20px', width: '20px' }} alt="logo" /></Typography>
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ width: '100%', marginBottom: '1em' }}>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <OutlinedInput autoFocus
            sx={{ padding: 0 }}
            value={aadharNumber1}
            inputProps={{
              style: {
                padding: '8px 10px', color: aadharNumber1 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                letterSpacing: aadharNumber1 ? '4px' : '10px',
                textAlign: 'center'
              },
              ref: ref1
            }}
            onChange={(e) => {
              let t = e.target.value;
              // t = t + getDots(e.target.value)

              if (t.length >= 5) {
                setAadharNumber2(e.target.value.charAt(e.target.value.length - 1))
                ref2.current.focus()
              } else {
                setAadharNumber1(t)
                if (t.length === 4) {
                  ref2.current.focus()
                }
              }
            }}
            placeholder='••••'
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <OutlinedInput
            // ref={ref2}
            sx={{ padding: 0 }}
            value={aadharNumber2}
            inputProps={{
              style: {
                padding: '8px 10px', color: aadharNumber2 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                letterSpacing: aadharNumber2 ? '4px' : '10px',
                textAlign: 'center'
              },
              ref: ref2
            }}
            onChange={(e) => {
              let t = e.target.value;
              // t = t + getDots(e.target.value)
              if (t.length >= 5) {
                setAadharNumber3(e.target.value.charAt(e.target.value.length - 1))
                ref3.current.focus()
              } else if (t.length === 0) {
                setAadharNumber2(t)
                ref1.current.focus()
              } else {
                setAadharNumber2(t)
                if (t.length === 4) {
                  ref3.current.focus()
                }
              }
            }}
            placeholder='••••'
          /></Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <OutlinedInput
            sx={{ padding: 0 }}
            value={aadharNumber3}
            inputProps={{
              style: {
                padding: '8px 10px', color: aadharNumber3 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                letterSpacing: aadharNumber3 ? '4px' : '10px',
                textAlign: 'center'
              },
              ref: ref3
            }}
            onChange={(e) => {
              let t = e.target.value;
              // t = t + getDots(e.target.value)
              if (t.length >= 5) {
                //hit api
                if (aadharNumber1.length >= 4 && aadharNumber2.length >= 4 && aadharNumber3.length >= 4) {

                  // debouncedAadharCall()
                }
              } else if (t.length === 0) {
                setAadharNumber3(t)
                ref2.current.focus()
              } else {
                setAadharNumber3(t)
                if (t.length === 4) {
                  //hit api
                  if (aadharNumber1.length >= 4 && aadharNumber2.length >= 4) {
                    debouncedAadharCall(aadharNumber1 + aadharNumber2 + t)
                  }
                }
              }
            }}
            placeholder='••••'
          /></Grid>
      </Grid>

      {(otpSent) ?

        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Typography variant="body2" color="secondary"
            sx={{ textAlign: 'center' }}>Enter the OTP recieved on your mobile
          </Typography>
          <FormControl>
            <OutlinedInput
              autoFocus
              sx={{
                margin: "0.2em 0 0.2em"
              }}
              value={otpInput}
              inputProps={{
                style: {
                  padding: '8px 10px', color: aadharNumber2 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                  letterSpacing: aadharNumber2 ? '4px' : '10px',
                  textAlign: 'center'
                }
              }}
              onChange={(e) => {
                setOtpInput(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitOtpRef.current.click()
                }
              }}
              placeholder='••••••'
            />
            <FormHelperText sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              margin: 0
            }}>
              <Button variant="text"
                onClick={(e) => { debouncedAadharCall(aadharNumber1 + aadharNumber2 + aadharNumber3) }}
                sx={{
                  width: 'fit-content',
                  margin: 0,
                  padding: 0,
                  fontWeight: 600,
                  marginLeft: 'auto'
                }} >Resend OTP</Button>
            </FormHelperText>
          </FormControl>

          <Button
            ref={submitOtpRef}
            variant="contained"
            disabled={(otpInput.length === 0) || loading}
            sx={{ margin: '1em 0em' }}
            onClick={async () => {
              setLoading(true)
              await aadhaarOtpCall(otpSent, otpInput, navigate)
                .then(x => {
                  if (x) {

                  }
                })
              setLoading(false)
            }}>Submit</Button>
        </Grid>
        : null}

      <Typography variant="body1" sx={{
        width: '100%',
        textAlign: 'center',
        fontSize: '0.8rem'
      }}>Don’t remember your Aadhaar Number?</Typography>
      <Link color="primary"
        sx={{
          margin: '0.5em auto 1em', padding: 0, width: 'fit-content', cursor: 'pointer', fontWeight: 600,
          fontSize: '0.8rem'
        }}
        onClick={() => navigate('/checkin/digilocker')}>
        Verify using Digilocker
      </Link>
      {loading ? <Backdrop open={loading}>
        <CircularProgress sx={{ position: "absolute", top: '45%', left: '45%' }} color="brand" />
      </Backdrop> : false}
    </Grid>
  )
}
