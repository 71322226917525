/* eslint-disable jsx-a11y/alt-text */
import { CheckCircle } from '@mui/icons-material';
import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import aadhaarSvg from '../../Assets/aadhaar.svg';
import BlueVerified from '../../Assets/blueVerified.svg';
import drivingLicense from '../../Assets/drivingLicense.jpg';
import livenessSvg from '../../Assets/liveness.svg';
import pan from '../../Assets/pan.svg';
import passport from '../../Assets/passport.webp';
import { ReactComponent as PoweredByTrential } from '../../Assets/poweredByTrential.svg';
import voterId from '../../Assets/voterId.jpg';
import { getCredentials, getWallet, oAuth } from '../../Config/api';
import { UIStore, updateStore } from '../../Config/store';
import { useQuery } from '../../Helper/helpers';
import { MAX_WIDTH } from '../../Utils/enums';
import { sampleAppConfig } from '../SampleApp/config';

export default function Home() {
  const navigate = useNavigate()
  const query = useQuery()
  const [credsRequested, setCredsRequested] = useState([])
  const [selectedCred, setSelectedCred] = useState({})
  const redirect_uri = UIStore.useState(s => s.redirect_uri) ?? {}
  const credentials = UIStore.useState(s => s.credentials) ?? {}
  const credentialLivenessCheckRecord = UIStore.useState(s => s.credentialLivenessCheckRecord) ?? {}
  const liveness = UIStore.useState(s => s.liveness) ?? {}
  const trentialId = UIStore.useState(s => s.trentialId) ?? {}

  const credentialsSupported = () => ({
    aadhaar: {
      logo: aadhaarSvg,
      title: 'Aadhaar Card',
      subtitle: 'UIDAI'
    },
    'driving-license': {
      logo: drivingLicense,
      title: 'Driving License',
      subtitle: 'Regional Transport Office (RTO)'
    },
    'voter-id': {
      logo: voterId,
      title: 'Voter ID',
      subtitle: 'Election Commission of India'
    },
    'passport': {
      logo: passport,
      title: 'Passport',
      subtitle: 'Ministry of External Affairs (MEA)'
    },
    liveness: {
      logo: livenessSvg,
      title: 'Liveness Check',
      subtitle: ''
    },
    pan: {
      logo: pan,
      title: 'PAN',
      subtitle: 'Income tax Department'
    },
  })

  const verified = {
    aadhaar: credentialLivenessCheckRecord['aadhaar'],
    liveness: (liveness.image ?? trentialId.referenceImageUrl),
    pan: credentialLivenessCheckRecord['pan'],
    'driving-license': credentialLivenessCheckRecord['driving-license'],
    'voter-card': credentialLivenessCheckRecord['voter-card'],
    'voter-id': credentialLivenessCheckRecord['voter-card'],
    'passport': credentialLivenessCheckRecord['passport']
  }


  useEffect(() => {

    getWallet()
      .then(() => {
        getCredentials()
      })
    let ar = []
    console.log("query.get('scope')", query.get('scope'))
    let q = (query.get('scope') ?? '').split(' ')
    q = q.map((z => {
      return z.split(':')[1]
    }))

    updateStore('searchedQuery', window.location?.search)
    Object.keys(credentialsSupported()).forEach((x) => {
      if (q.includes(x)) {
        ar.push({
          ...credentialsSupported()[x],
          key: x
        })
      }
    })
    setCredsRequested(ar)

    if (query.get('redirect_uri')) {
      updateStore('redirect_uri', query.get('redirect_uri'))
    }
  }, [])


  console.log('selectedCred', selectedCred)

  return (
    <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="space-between"
      sx={{ padding: '1em', flex: 1, position: 'relative' }}>

      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '1em' }}>
        <img style={{
          textAlign: 'center',
          height: '100px'
        }} src={BlueVerified} />
        <Typography variant="body1" sx={{
          textAlign: 'center',
          fontWeight: 'bold',
        }}>Secure your identity by verifying</Typography>

        <Typography variant="body2" sx={{
          textAlign: 'center',
        }}>Select verifications to share with {sampleAppConfig.name}</Typography>


        <List sx={{ width: '100%' }}>
          {credsRequested.map((c, i) => <ListItem
            key={"credsRequested" + i}
            onClick={() => {
              if (!verified[c.key]) {
                if (c.key === 'liveness') {
                  window.snackbar("info", 'Liveness check is done while adding a new credential to wallet.')
                } else {
                  navigate('/docs', { state: { screen: c.key } })
                }
              } else {
                setSelectedCred(qw => {
                  let temp = { ...qw }
                  temp[c.key] = !qw[c.key];
                  return temp
                })
              }
            }}
            sx={{
              border: selectedCred[c.key] ? '2px solid green' : '1px solid #DADADA',
              borderRadius: '20px',
              margin: '1em 0em',
              cursor: 'pointer'
            }}
            secondaryAction={
              verified[c.key] ? <Typography variant="body2" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: '#5CC278',
                fontWeight: 'bold',
              }}><CheckCircle color="success" /> &nbsp;Verified</Typography> : false
            }>
            <ListItemAvatar>
              <Avatar sx={{
                boxShadow: '0px 4px 4px 0px #3232321F',
                backgroundColor: '#ffffff'
              }}>
                <img src={c.logo} style={{ height: '30px', width: '30px' }} alt="logo" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={c.title}
              secondary={c.subtitle}
              primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
              secondaryTypographyProps={{ sx: { margin: '0em', padding: '0em' } }}
            />
          </ListItem>)}
        </List>
      </Grid>

      {
        (Object.keys(selectedCred).filter(zx => selectedCred[zx]).length > 0) ? <Button
          variant="contained" color="primary"
          sx={{ width: '96%', margin: '1em auto -1em' }}
          onClick={async () => {
            const scope = Object.keys(selectedCred).reduce((acc, z) => {
              if (selectedCred[z]) {
                acc.push('presentation:' + z + ':read')
              }
              return acc;
            }, []).join("+")
            // let x = {
            //   aadhaar: {
            //     'Aadhaar Number': verified.aadhaar.find(x => x.name === 'Aadhaar ID')?.value,
            //     'Name from Aadhaar': verified.aadhaar.find(x => x.name === 'Name')?.value,
            //     'Photo': verified.aadhaar.find(x => x.name === 'Photo')?.value
            //   },
            //   liveness: {
            //     'Aadhaar Photo': verified.aadhaar.find(x => x.name === 'Photo')?.value,
            //     'Live Photo': liveness.image ?? trentialId.referenceImageUrl
            //   },
            //   pan: {
            //     'PAN': verified.pan.find(x => x.name === "Permanent Account Number")?.value,
            //     'Name from PAN': verified.pan.find(x => x.name === 'Name')?.value,
            //   },
            // }
            // let y = jsonToBase64({ 'sample': 'sample' })


            // try {
            //   const response = await axios('https://ssp.aashishprasad-c.repl.co/redirect', {
            //     method: "GET",
            //     redirect: "manual", // This prevents automatic following of redirects
            //     withCredentials: false
            //   });
            //   console.log('response', response)
            //   if (response) {
            //     const redirectUrl = response.headers.get("location"); // Get the new URL from the 'Location' header
            //     // window.location.href = redirectUrl; // Manually initiate the redirection
            //   } else {
            //     // Handle other responses here
            //   }
            // } catch (error) {
            //   // Handle error here
            // }

            await oAuth(query.get('response_type'), query.get('client_id'),
              query.get('redirect_uri'), scope, query.get('state'))
              .then((x) => {
                // logout();
              })
            // logout();
            // navigate(redirect_uri + "?payload=" + y, { state: { data: x } })
          }}>Submit ({Object.keys(selectedCred).length})</Button> :
          <div
            style={{
              padding: '1em 0', position: 'fixed',
              bottom: 0, left: isDesktop ? 'auto:0, right' : isDesktop ? 'auto' : 0, display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px -4px 12px 0px #0000000D',
              width: MAX_WIDTH,
              backgroundColor: '#ffffff'
            }}>
            <PoweredByTrential />
          </div>
      }
    </Grid >
  )
}
