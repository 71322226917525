/* eslint-disable eqeqeq */
import { Loader } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';
import { ErrorOutline } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { receieveOob, startTrentialIdCreation } from '../../Config/api';
import { UIStore, updateStore } from '../../Config/store';
import { IMAGE_COMPARE_CONFIDENCE_SCORE, LIVENESS_CONFIDENCE_SCORE, getIdentifierKey } from '../../Utils/enums';
import { compareImageSimilarity, getSessionId, getSessionResponse, getSessionToken } from "./apis";



function FaceLiveness({ faceLivenessAnalysis }) {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)
    const docData = UIStore.useState(s => s.docData)
    const credentialLivenessCheckRecord = UIStore.useState(s => s.credentialLivenessCheckRecord)



    const fetchSessionToken = async () => {
        /*
         * API call to create the Face Liveness Session
         */
        setLoading(true);
        const sToken = await getSessionToken();
        const sessionIdFromParams = await getSessionId(sToken)
        setSessionId(sessionIdFromParams)
        setLoading(false);
        console.log("TEST close deeplink", "trential://payload")
        //   setTimeout(()=>{
        //     // window.history.back()
        //     // window.close()
        //     window.location.href = "https://kla123.netlify.app/"
        //   }, 3000)
    }
    useEffect(() => {
        fetchSessionToken()
    }, [])

    /*
   * Get the Face Liveness Session Result
   */
    const handleAnalysisComplete = async (data) => {
        /*
         * API call to get the Face Liveness Session result
         */
        console.log('handleAnalysisComplete', data)
        faceLivenessAnalysis(data?.data)


        const pollingId = setInterval(() => {
            getSessionResponse(sessionId)
                .then(async (response) => {
                    if (!response.error) {
                        if (response?.data?.status == 'SUCCEEDED') {
                            console.log('Session Response', response?.data)
                            clearInterval(pollingId)
                            if (response.data.confidence < LIVENESS_CONFIDENCE_SCORE) {
                                window.snackbar('error', 'Liveness check failed, Try again')
                                await fetchSessionToken();
                            } else {
                                console.log('docData', docData)
                                const compareImage = await compareImageSimilarity(
                                    response?.data?.referenceImageUrl,
                                    docData?.Photo ?? docData?.photo
                                )
                                console.log('compareImage', compareImage)

                                if (IMAGE_COMPARE_CONFIDENCE_SCORE !== 0 ? (!compareImage?.error && compareImage?.data > IMAGE_COMPARE_CONFIDENCE_SCORE) : true) {
                                    updateStore("liveness", {
                                        image: response?.data?.referenceImageUrl
                                    })

                                    let qRes = true;
                                    if (docData.invitationUrl) {
                                        qRes = await receieveOob(docData.invitationUrl)
                                    }
                                    if (qRes) {
                                        qRes = await startTrentialIdCreation(docData, response?.data?.referenceImageUrl)
                                    }

                                    if (qRes) {
                                        updateStore('credentialLivenessCheckRecord', {
                                            ...credentialLivenessCheckRecord,
                                            [docData[getIdentifierKey(docData.documentName)]]: true
                                        })
                                        setTimeout(() => {
                                            navigate('/checkin/displayInfo', { state: { showLoading: true } })
                                        }, 1000);
                                    } else {
                                        // window.snackbar('error', 'Trential ID creation failed')
                                        navigate(-1)
                                    }

                                } else {
                                    window.snackbar('error', "Your face didn't match your document, either try again or use another document")
                                    navigate(-1)
                                }
                            }
                        } else if (response?.data?.status == 'EXPIRED' || response?.data?.status == 'FAILED') {
                            clearInterval(pollingId)
                            window.snackbar('error', 'Liveness check failed, Try again')
                            await fetchSessionToken();
                        }
                    } else {
                        window.snackbar('error', 'Liveness check failed, Try again')
                        await fetchSessionToken();
                    }
                })
        }, 2000) //each count lasts for 2 seconds
        // window.location.href = "https://www.trential.com";
        // window.location.replace("trential://payload")
        // redirectToApp()
    };

    console.log('sessionId', sessionId)

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetector
                    sessionId={sessionId}
                    region="ap-south-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={(error) => {
                        console.error(error);
                        // redirectToApp()
                    }}
                    components={{
                        ErrorView: ({ children }) => {
                            document.querySelector('.amplify-liveness-cancel-container').style.display = "none"
                            return (
                                <Card variant="error" sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '1em'
                                }}>
                                    <ErrorOutline color="error" sx={{ margin: '2px', fontSize: 40 }} />
                                    <Typography variant='body2'>
                                        Face is not aligned properly within the stipulated time
                                    </Typography>
                                    <Button variant={'contained'} color="primary"
                                        sx={{ width: 'fit-content' }}
                                        onClick={async () => {
                                            await fetchSessionToken();
                                        }}>
                                        Try Again
                                    </Button>
                                </Card>
                            );
                        },
                    }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
