/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowBack, CheckCircle, Upload } from '@mui/icons-material'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDigilockerToken, getDlInDigilocker, getIssuedDocsFromDigilocker, masterDigilocker } from '../../Config/api'
import { useQuery } from '../../Helper/helpers'

export default function Digilocker() {

    const navigate = useNavigate()
    const query = useQuery()
    const submitRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingDc, setLoadingDc] = useState({})
    const [dcs, setDcs] = useState({})
    const [field, setField] = useState("")
    const [loadingDialog, setLoadingDialog] = useState(false)

    const fn = async () => {
        // alert(query.get('code'))
        if (query.get('code')) {
            setLoading(true)
            const token = await getDigilockerToken(query.get('code'))
            const docs = await getIssuedDocsFromDigilocker(token) ?? []

            // let dc = {
            //     "Aadhaar Card": null,
            //     "Driving License": null,
            //     "PAN Verification Record": null
            // }
            let dc = {}
            let dcAr = Object.keys(dc)
            if (docs) {
                docs.forEach(element => {
                    // const fnd = dcAr.find((z => z === element.name));

                    // if (fnd) {
                    dc[element.name] = element
                    // }
                });
            } else {
                // navigate('/checkin')
            }
            setDcs(dc)
            setLoading(false)
        } else {
            window.location.replace('https://indisi-issuer-trential-apis-dev-7kuqnnxqla-el.a.run.app/api/1.0/digilocker/authorize?redirect=' +
                window.location.origin + '/checkin/digilocker')
        }
    }
    useEffect(() => {
        fn()
    }, [])

    return (
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ padding: '1em' }}>
            <Typography variant="h6" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}><IconButton
                onClick={() => navigate(-1)}
            ><ArrowBack /></IconButton> Add Documents from DigiLocker </Typography>

            {Object.values(dcs).filter(x => x).length > 0 ? <Typography variant="body1" sx={{
                textAlign: 'left',
                marginLeft: '1em'
            }}> We found these documents in your Digilocker Wallet, upload a document to complete checkin </Typography> :
                <CircularProgress sx={{ margin: 'auto' }} />}
            {
                loading ? null : <List dense sx={{ width: '100%' }}>
                    {Object.keys(dcs)?.map((item, i) => {
                        return (
                            <ListItem
                                secondaryAction={
                                    loadingDc !== dcs[item]?.uri ? <IconButton
                                        disabled={!dcs[item]}
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            setLoadingDc(dcs[item]?.uri)
                                            await masterDigilocker(dcs[item]?.uri, item, navigate)

                                            setLoadingDc(null)
                                        }}
                                        edge="end" aria-label="delete">
                                        <Upload />
                                    </IconButton> : <CircularProgress size={14} />
                                }
                            >
                                <ListItemAvatar>
                                    <CheckCircle color={dcs[item] ? "success" : "disabled"} sx={{ fontSize: 30 }} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={dcs[item]?.name ?? item}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontWeight: 600,
                                            margin: 0, padding: 0,
                                        }
                                    }}
                                    // secondary={(dcs[item] || item === "Aadhaar Card") ? "" : <Link color="secondary"
                                    //     sx={{ margin: 0, padding: 0, marginRight: 'auto', width: 'fit-content', cursor: 'pointer' }}
                                    //     onClick={() => {
                                    //         setOpen(item)
                                    //     }}>
                                    //     Click here to fetch in Digilocker and upload
                                    // </Link>}
                                    secondaryTypographyProps={{
                                        sx: {
                                            margin: 0, padding: 0,
                                        }
                                    }}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            }

            <Dialog
                maxWidth="md"
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                sx={{ padding: '1em' }}
            ><DialogTitle>Pull {open} into Digilocker</DialogTitle>
                {!loadingDialog ? <DialogContent>

                    <TextField fullWidth autoFocus
                        sx={{ margin: '1em 0' }}
                        id="Name"
                        label={open + " Number"}
                        value={field}
                        onChange={(event) => {
                            setField(event.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                submitRef.current.click()
                            }
                        }}
                    // InputProps={{
                    //   startAdornment: <InputAdornment position="start">+91-</InputAdornment>,
                    // }}
                    />
                    <Button ref={submitRef} variant="contained" color="primary"
                        onClick={async () => {
                            setLoadingDialog('Fetching...');
                            await getDlInDigilocker(field)
                                .then(async (result) => {
                                    console.log('result', result)
                                    if (result) {
                                        setLoadingDialog('Uploading...');
                                        await masterDigilocker(result?.uri)
                                    }
                                }).finally(() => {
                                    setLoadingDialog(false)
                                    setOpen(false)
                                })
                        }}>Fetch</Button>
                </DialogContent> : <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <CircularProgress sx={{ margin: '1em auto' }} />
                    <Typography variant="body2">{loadingDialog}</Typography>
                </Grid>}
            </Dialog>
        </Grid >
    )
}
