/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowBack } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, IconButton, LinearProgress, List, ListItem, ListItemText, Typography, linearProgressClasses, styled } from '@mui/material'
import { animate } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as BrandLogo } from '../../Assets/brandLogo.svg'
import { ReactComponent as Check } from '../../Assets/check.svg'
import { putVcInWallet, receieveOob } from '../../Config/api'
import { UIStore, fetchFromStore, updateStore } from '../../Config/store'
import { changeToSentenceCase, displayTime, getImageData } from '../../Helper/helpers'
import { IMAGE_COMPARE_CONFIDENCE_SCORE, MAX_WIDTH, MAX_WIDTH_HALF, getIdentifierKey } from '../../Utils/enums'
import { compareImageSimilarity } from '../Liveness/apis'

const ignoreKeys = ['image', 'documentName', 'docVal', 'invitationUrl', 'photo', 'Photo', 'dontUploadDoc']
export default function DisplayInfo() {

    const navigate = useNavigate()
    const { state } = useLocation();
    const trentialId = UIStore.useState(s => s.trentialId)
    const credentialLivenessCheckRecord = UIStore.useState(s => s.credentialLivenessCheckRecord)
    const [loadingContinue, setLoadingContinue] = useState(false)

    const docData = UIStore.useState(s => s.docData) ?? {
        documentName: 'Passport',
        photo: 'https://pbs.twimg.com/media/D_I8P84WwAMys7B.jpg',
        name: 'Akash Singhania',
        gender: 'M',
        passportNumber: 'P2251480',
        expiryDate: displayTime(null, 'DD/MM/YYYY'),
        issueDate: displayTime(null, 'DD/MM/YYYY'),
    };
    const statusIconRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        let timer = null;
        if (loading) {
            let prev = 0
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress >= 96) {
                        clearInterval(timer);
                        return 100;
                    }
                    const diff = Math.random() * 10;
                    const p1 = Math.floor(prev / 25)
                    const p2 = Math.floor(Math.min(oldProgress + diff, 100) / 25)
                    prev = Math.min(oldProgress + diff, 100)
                    console.log('p1 != prev', p1, prev)
                    if (p1 !== p2) {
                        // animate(statusTextRef.current, { opacity: [0, 1], scale: [0, 1] }, { duration: 0.5 })
                        animate(statusIconRef.current, { opacity: [0, 1], scale: [0, 1] }, { duration: 0.4 })
                    }
                    return Math.min(oldProgress + diff, 100);
                });
            }, 200);

        }
        return () => {
            clearInterval(timer);
        };
    }, [loading]);

    const states = [
        {
            title: 'Uploading your Details',
            progress: 20
        },
        {
            title: 'Verifying Details',
            progress: 40
        },
        {
            title: 'Adding a Security Layer',
            progress: 60
        },
        {
            title: 'Protecting your Document',
            progress: 80
        }, {
            title: 'Your Document is Ready',
            progress: 100
        }
    ]
    console.log('progress', progress)

    useEffect(() => {
        if (state?.showLoading) {

            startLoadingProcess()
        } else {
            setProgress(100)
        }
    }, [])


    useEffect(() => {
        if (!docData?.documentName) {
            navigate('/checkin')
        }
    }, [])

    const startLoadingProcess = async () => {
        setLoading(true)
        if (!docData.dontUploadDoc) {

            setProgress(0)
            return putVcInWallet(docData.docVal)
                .then((x) => {
                    if (x) {
                        setProgress(100)
                    } else {
                        navigate(-1)
                    }
                })
        } else if (docData.dontUploadDoc === 'oob') {
            if (docData.invitationUrl && !state?.showLoading) {

                setProgress(0)
                await receieveOob(docData.invitationUrl).then((x) => {
                    if (x) {
                        setProgress(100)
                    } else {
                        navigate(-1)
                    }
                })
            }
        }
    }

    return loading ? <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '1em 0', height: '100%' }}>
        <BrandLogo />

        <Typography
            variant="h6"
            sx={{
                margin: '2em 1em 1em', fontSize: '0.8rem',
                color: '#404052'
            }}>{progress <= 99 ? 'Please Wait...' : ''}
        </Typography>
        <BorderLinearProgress variant="determinate" value={progress} />
        <Typography ref={statusIconRef} variant="h6" sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }}><IconButton
        ><Check /></IconButton><h5>{states[Math.floor(progress / 25)]?.title}</h5> </Typography>
        {progress >= 100 ? <Button variant="contained" color="primary"
            sx={{ position: 'fixed', bottom: '8px', left: isDesktop ? 'auto' : 0, right: isDesktop ? 'auto' : 0, width: '96%', margin: 'auto' }}
            onClick={() => navigate('/oauth/authorize' + fetchFromStore('searchedQuery'))}>Continue to Verify</Button> : null}
    </Grid> : (
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ padding: '1em 0' }}>
            <Typography variant="h6" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}><IconButton
                onClick={() => navigate(-1)}
            ><ArrowBack /></IconButton> {docData.documentName}</Typography>

            {(docData.photo ?? docData.Photo) ?
                <img src={getImageData((docData.photo ?? docData.Photo))} alt="doc-img" style={{ width: '50%', margin: '1em' }} /> : null}
            <List dense style={{ width: '100%' }}>
                {
                    Object.keys(docData).map((item, index) => (!ignoreKeys.includes(item) && docData[item]) ? <ListItem divider
                        key={item + "-" + index}>
                        <ListItemText
                            primary={changeToSentenceCase(item)}
                            secondary={docData[item]}
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: '0.7rem',
                                    fontWeight: 500,
                                    margin: 0,
                                    color: '#A6A6A6'
                                }
                            }}
                            secondaryTypographyProps={{
                                sx: {
                                    fontSize: '1rem',
                                    color: '#404052',
                                    fontWeight: 'bold',
                                    margin: 0
                                }
                            }}
                        />
                    </ListItem> : null)
                }
            </List>
            <span style={{ margin: "2em" }}></span>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    position: 'fixed', bottom: '5px', width: '95%', maxWidth: MAX_WIDTH + 'px', margin: '0 8px',
                    backgroundColor: '#ffffff'
                }}>
                <Button variant="outlined" color="brand"
                    sx={{ width: '45%', maxWidth: MAX_WIDTH_HALF }}
                    onClick={async () => {
                        navigate(-1)
                    }}>Retry</Button>
                <LoadingButton variant="contained" color="brand"
                    loading={loadingContinue}
                    disabled={loadingContinue}
                    sx={{ width: '45%', maxWidth: MAX_WIDTH_HALF }}
                    onClick={async () => {
                        setLoadingContinue(true)
                        // if (credentialLivenessCheckRecord[docData[getIdentifierKey(docData.documentName)]]
                        //     && trentialId?.referenceImageUrl) {

                        //     await startLoadingProcess()
                        //     updateStore("liveness", {
                        //         image: trentialId?.referenceImageUrl
                        //     })
                        // } else {
                        if (docData?.Photo ?? docData?.photo) {
                            if (!trentialId) {
                                setLoadingContinue(false)
                                navigate('/liveness')
                            } else {
                                const compareImage = await compareImageSimilarity(
                                    trentialId.referenceImageUrl,
                                    docData?.Photo ?? docData?.photo,
                                    'sourceImage'
                                )
                                console.log('compareImage', compareImage)

                                if (IMAGE_COMPARE_CONFIDENCE_SCORE !== 0 ? (!compareImage?.error && compareImage?.data > IMAGE_COMPARE_CONFIDENCE_SCORE) : true) {
                                    updateStore("liveness", {
                                        image: trentialId.referenceImageUrl
                                    })
                                    updateStore('credentialLivenessCheckRecord', {
                                        ...credentialLivenessCheckRecord,
                                        [docData[getIdentifierKey(docData.documentName)]]: true
                                    })
                                    await startLoadingProcess()
                                    setLoadingContinue(false)
                                } else {
                                    setLoadingContinue(false)
                                    window.snackbar('error', "Your face didn't match your document, either try liveness check again or use another document")
                                    navigate('/liveness')
                                }
                            }
                        } else {
                            await startLoadingProcess()
                        }

                        // }

                    }}>Continue</LoadingButton>
            </Grid>
        </Grid >
    )
}


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    margin: '0.6em',
    width: '80%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        background: '#d5d5d5',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        background: 'linear-gradient(90deg, #1667DF 0%, #003687 100%)',
    },
}));