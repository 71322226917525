import axios from 'axios'

import { SERVER_ENDPOINT, X_API_KEY } from './config'
import routes from './routes'

axios.defaults.withCredentials = true

export const getSessionToken = async () => {
  console.log('getSessionToken called')
  console.log(`${SERVER_ENDPOINT}${routes.liveness.sessionToken}`)

  try {
    const res = await axios({
      method: 'get',
      url: `${SERVER_ENDPOINT}${routes.liveness.sessionToken}`,
      headers: {
        'X-API-KEY': X_API_KEY,
      },
    })
    console.log('token', res.data)
    return res.data.data
  } catch (err) {
    console.log('getSessionToken Error', JSON.stringify(err))
    return false
  }
}

export const getSessionId = async (sessionToken) => {
  console.log('getSessionId called')
  console.log(`${SERVER_ENDPOINT}${routes.liveness.sessionId}`)

  try {
    const res = await axios({
      method: 'get',
      url: `${SERVER_ENDPOINT}${routes.liveness.sessionId}`,
      headers: {
        'X-API-KEY': X_API_KEY,
        Authorization: `Bearer ${sessionToken}`,
      },
    })
    console.log('getSessionId', res.data.data)
    return res.data.data
  } catch (err) {
    console.log('getSessionId Error', JSON.stringify(err))
    return false
  }
}

export const getSessionResponse = async (sessionId, ignoreLivenessResult) => {
  console.log('getSessionResponse called')
  console.log(
    `${SERVER_ENDPOINT}${routes.liveness.sessionId}/${sessionId}${ignoreLivenessResult ? `?ignoreLivenessResult=${ignoreLivenessResult}` : ''
    }`
  )

  try {
    const res = await axios({
      method: 'get',
      url: `${SERVER_ENDPOINT}${routes.liveness.sessionId}/${sessionId}${ignoreLivenessResult ? `?ignoreLivenessResult=${ignoreLivenessResult}` : ''
        }`,
      headers: {
        'X-API-KEY': X_API_KEY,
      },
    })
    console.log('getSessionResponse', res.data.data)
    return {
      error: false,
      statusCode: 200,
      data: res.data.data,
    }
  } catch (e) {
    console.log('getSessionResponse Error', JSON.stringify(e))
    return {
      error: true,
      statusCode: e?.response?.status,
      errorMessage: e?.response?.data?.message,
    }
  }
}

export const compareImageSimilarity = async (sourceImage, targetImage, key = null) => {
  console.log('compareImages called', sourceImage, targetImage)
  let obj = {}
  if (sourceImage.includes('https://')) {
    obj.sourceImageUrl = sourceImage
  } else {
    obj.sourceImage = sourceImage
  }

  if (targetImage.includes('https://')) {
    obj.targetImageUrl = targetImage
  } else {
    obj.targetImage = targetImage
  }
  try {
    const res = await axios({
      method: 'post',
      url: `${SERVER_ENDPOINT}${routes.compare.image}`,
      headers: {
        'X-API-KEY': X_API_KEY,
      },
      data: obj,
    })
    console.log('compareImages', res.data.data)
    return {
      error: false,
      statusCode: 200,
      data: res?.data?.data,
    }
  } catch (e) {
    console.log('compareImages Error', JSON.stringify(e))
    return {
      error: true,
      statusCode: e?.response?.status,
      errorMessage: e?.response?.data?.message,
    }
  }
}

export const submitFailedComparisonReport = async (sessionId, sourceImage, targetImage) => {
  console.log('submitFailedComparisonReport called', sessionId, sourceImage, targetImage)
  let obj = {}
  if (sourceImage.includes('https://')) {
    obj.sourceImageUrl = sourceImage
  } else {
    obj.sourceImage = sourceImage
  }

  if (targetImage.includes('https://')) {
    obj.targetImageUrl = targetImage
  } else {
    obj.targetImage = targetImage
  }
  try {
    const res = await axios({
      method: 'put',
      url: `${SERVER_ENDPOINT}${routes.failed.report}`,
      headers: {
        'X-API-KEY': X_API_KEY,
      },
      data: {
        sessionId,
        ...obj
      },
    })
    console.log('submitFailedComparisonReport', res.data.data)
    return {
      error: false,
      statusCode: 200,
      data: res.data.data,
    }
  } catch (e) {
    console.log('submitFailedComparisonReport Error', JSON.stringify(e))
    return {
      error: true,
      statusCode: e?.response?.status,
      errorMessage: e?.response?.data?.message,
    }
  }
}
