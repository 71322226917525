import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SecondaryTopBar } from '../Base';
import Digilocker from '../Checkin/digilocker';
import Liveness from '../Liveness';
import Aadhaar from './aadhaar';

export default function DocsFlow() {
    const navigate = useNavigate()
    const state = useLocation().state;
    const screen = state?.screen
    const screens = {
        'aadhaar': () => <SecondaryTopBar><Aadhaar /></SecondaryTopBar>,
        'driving-license': () => <Digilocker />,
        'pan': () => <Digilocker />,
        'voter-id': () => {
            return <Navigate to='/checkin/scan' state={{ title: "Voter ID Card", docVal: 'voter-id' }} replace />
        },
        'passport': () => {
            return <Navigate to='/checkin/scan' state={{ title: "Passport", docVal: 'passport' }} replace />
        },
        'liveness': () => <Liveness />,
    }


    return screens[screen]()

}
