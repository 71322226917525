/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircle, ChevronLeft, Close, DeleteOutline } from '@mui/icons-material';
import { Button, Card, CircularProgress, Dialog, Divider, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import CustomStepper from '../../Components/UI/stepper';
import { preCheckIn } from '../../Config/api';
import { UIStore } from '../../Config/store';
import { MAX_WIDTH, getIdentifierKey, getNameKey } from '../../Utils/enums';
import { Header } from './checkedIn';

export default function Payment({ paymentDone, setPaymentDone }) {

    const navigate = useNavigate()

    const selectedOffers = UIStore.useState(s => s.selectedOffers)
    const docData = UIStore.useState(s => s.docData)
    const selectedCredentialAttributes = UIStore.useState(s => s.selectedCredentialAttributes)
    const liveness = UIStore.useState(s => s.liveness)

    const room = Object.values(selectedOffers).filter(x => x.type === 'ROOM')
    const others = Object.values(selectedOffers).filter(x => x.type !== 'ROOM')
    const [enableConfetti, setEnableConfetti] = useState(false)
    const [openPaymentLoading, setOpenPaymentLoading] = useState(false)

    const finishPreCheckin = async () => {
        const identifier = docData[getIdentifierKey(docData.documentName)]
        return preCheckIn(
            selectedCredentialAttributes,
            liveness.image,
            identifier,
            docData[getNameKey(docData.documentName)]
        ).then(x => {
            if (!x.error) {
                setPaymentDone(true)
                setEnableConfetti(true)
                setTimeout(() => {
                    setEnableConfetti(false)
                }, 2500);
            } else {
                window.snackbar("error", "Precheckin failed")
                navigate(-1)
            }
        }).catch(e => {
            window.snackbar("error", "Precheckin failed")
            navigate(-1)
        })
    }

    useEffect(() => {
        if (paymentDone === "loading") {

            // finishPreCheckin()
        }
    }, [paymentDone])

    useEffect(() => {
        if (openPaymentLoading) {
            setPaymentDone(false)
            setTimeout(() => {
                setPaymentDone('loading')
                setOpenPaymentLoading(false)
            }, 2000);
        }
    }, [openPaymentLoading])


    return <>
        {!paymentDone ? (
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ padding: '0.5em 0', height: '100%' }}>
                <Typography variant="h6" sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    textAlign: 'left',
                    margin: 0,
                    marginRight: 'auto'
                }}><IconButton
                    onClick={() => navigate(-1)}
                ><ChevronLeft /></IconButton> Back </Typography>
                <Header />

                {room.length > 0 && room?.map((z, i) =>
                    <Card key={z.id} variant="outlined" sx={{ width: '96%', margin: '1em' }}>
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                padding: '1em'
                            }}>
                            <Grid item xs={5}>
                                <Typography variant="h5" sx={{
                                    fontWeight: 500,
                                    textAlign: 'left',
                                    fontSize: '0.9rem'
                                }}>Room Upgraded to</Typography>
                                <Typography variant="h5" sx={{
                                    fontWeight: 600,
                                    textAlign: 'left',
                                    fontSize: '1.2rem'
                                }}>{z.title}</Typography>
                                <Typography variant="h6" sx={{
                                    textAlign: 'left',
                                    fontWeight: 500,
                                    margin: '0.4em 0'
                                }}>{z.subtitle1}</Typography>
                                <Button
                                    sx={{
                                        marginRight: 'auto', fontSize: '1rem', width: 'fit-content',
                                        padding: 0,
                                        fontWeight: 500
                                    }}>View Details {">"}</Button>
                            </Grid>
                            <Grid item xs={5}>
                                <img src={z.image} alt={z.title}
                                    style={{ maxHeight: '160px', width: '160px', borderRadius: '16px' }} />
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} />
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                padding: '0.4em 1em'
                            }}>
                            <Typography variant="h6" sx={{
                                textAlign: 'left',
                            }}>Total Payable</Typography>

                            <Typography variant="h6" sx={{
                                margin: 0,
                                textAlign: 'left',
                            }}>₹{z.pay1} <IconButton
                                color="brand"
                                onClick={() => {
                                    let qw = { ...selectedOffers }
                                    delete qw[z.id]
                                    UIStore.update(s => {
                                        s.selectedOffers = qw;
                                    })
                                }}><DeleteOutline /></IconButton></Typography>

                        </Grid>
                    </Card>)}

                {others.length > 0 && <Card variant="outlined" sx={{ width: '96%', margin: '1em', padding: '1em' }}>
                    <Typography variant="h5" sx={{
                        fontWeight: 500,
                        textAlign: 'left',
                        fontSize: '0.9rem'
                    }}>Addon selected to</Typography>
                    <Typography variant="h6" sx={{
                        textAlign: 'left',
                    }}>{others.length + " service" + (others.length === 1 ? "" : "s") + " selected"}</Typography>

                    <List dense sx={{ width: '100%', padding: 0 }}>
                        {others.map((item, i) =>
                            <ListItem key={item.id}
                                sx={{ width: '100%', padding: 0 }}
                                secondaryAction={
                                    <Typography variant="h6" sx={{
                                        margin: 0,
                                        textAlign: 'left',
                                    }}>₹{item.pay1} <IconButton
                                        color="default"
                                        onClick={() => {
                                            let qw = { ...selectedOffers }
                                            delete qw[item.id]
                                            UIStore.update(s => {
                                                s.selectedOffers = qw;
                                            })
                                        }}><Close /></IconButton></Typography>
                                }
                            >
                                <ListItemText
                                    primary={item.title}
                                />
                            </ListItem>
                        )}
                    </List>
                    <Divider sx={{ width: '100%' }} />
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            padding: '0.4em 1em'
                        }}>
                        <Typography variant="h6" sx={{
                            textAlign: 'left',
                        }}>Total Payable</Typography>

                        <Typography variant="h6" sx={{
                            margin: 0,
                            textAlign: 'left',
                        }}>₹{others.reduce((acc, z) => z.pay1 + acc, 0)} <IconButton
                            color="brand"
                            onClick={() => {
                                let qw = {}
                                room.forEach(zz => {
                                    qw[zz.id] = zz
                                })
                                UIStore.update(s => {
                                    s.selectedOffers = qw;
                                })
                            }}><DeleteOutline /></IconButton></Typography>

                    </Grid>
                </Card>}

                <span>
                    <Button variant="contained" color="primary"
                        sx={{ width: '45%', visibility: 'hidden', margin: '1.2em' }}>.</Button>
                </span>
                <Grid container sx={{
                    ...(isDesktop ? {
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-49.4%)',
                        width: MAX_WIDTH
                    } : {
                        position: 'fixed',
                        bottom: 0, left: 0,
                    }),
                    backgroundColor: '#ffffff'
                }}>
                    <Divider sx={{ width: '100%' }} />
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            padding: '1em'
                        }}>
                        <ListItemText
                            sx={{ margin: '0.6em', marginRight: 'auto' }}
                            primary="You Pay"
                            secondary={"₹" + Object.values(selectedOffers).reduce((acc, z) => z.pay1 + acc, 0)}
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: '0.8rem',
                                    fontWeight: 700,
                                    margin: 0,
                                    color: '#444444',
                                    textAlign: 'left'
                                }
                            }}
                            secondaryTypographyProps={{
                                color: 'brand',
                                sx: {
                                    fontSize: '1.2rem',
                                    fontWeight: 700,
                                    margin: 0,
                                    color: '#000000',
                                    textAlign: 'left'
                                }
                            }}
                        />
                        <Button variant="contained" color="primary"
                            sx={{ width: '45%' }}
                            onClick={async () => {
                                setOpenPaymentLoading(true)
                            }}>Pay Now</Button>
                    </Grid></Grid>
            </Grid>
        ) : paymentDone === "loading" ? <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <CircularProgress sx={{ margin: '1em auto' }} />
            <Typography variant="body2">Pre checking is in progress</Typography>
        </Grid> : <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ padding: '0.5em 0', height: '100%', flexWrap: 'nowrap' }}>

            <Header />
            {/* <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                run={paymentDone}
                recycle={false}
                confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: 40 }}
                initialVelocityY={5}
                numberOfPieces={500}
            /> */}
            {enableConfetti ? <ConfettiExplosion
                force={0.8}
                duration={3000}
                particleCount={250}
                width={1600}
            /> : null}
            <Card variant="outlined"
                sx={{
                    padding: '2em 1em',
                    margin: '1em',
                    backgroundColor: '#EEFFF3',
                    border: '2px solid #5CC278',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '500px'
                }}>
                <CheckCircle color="success" sx={{ fontSize: 100 }} />

                <Typography variant="h5"
                    color="primary" sx={{
                        fontWeight: 600,
                        textAlign: 'center',
                        fontSize: '1.2rem',
                        color: '#5CC278'
                    }}>Congratulations!</Typography>
                <Typography variant="h6" sx={{
                    textAlign: 'center',
                    fontWeight: 500,
                }}>You have successfully completed the Pre-Check In process.</Typography>
                <Typography variant="h6" sx={{
                    textAlign: 'center',
                    fontWeight: 500,
                    margin: '0em 0'
                }}>Have a great Stay at Hotel ITC Maurya</Typography>
            </Card>
            <Grid sx={{
                padding: '2em 0.2em',
                margin: '0.2em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '500px',
                width: '100%'
            }}><Typography variant="h5" sx={{
                fontWeight: 700,
                textAlign: 'left',
                marginRight: 'auto',
                padding: '0 1em',
                fontSize: '0.9rem'
            }}>What to do next?</Typography>
                <CustomStepper
                    steps={['When you visit, Hotel ITC Maurya, just tell your name at the Reception',
                        'Collect your Room keys']} />
            </Grid>




        </Grid>}

        <Dialog
            maxWidth="xs"
            fullWidth
            open={openPaymentLoading}
            onClose={() => { }}
            sx={{ padding: '1em' }}
        >
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center">
                <CircularProgress sx={{ margin: '1em auto' }} />
                <Typography variant="body2">Payment in progress</Typography>
            </Grid>
        </Dialog>
    </>
}