import '@aws-amplify/ui-react/styles.css';
import { ArrowBack } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import React from "react";
import { useNavigate } from 'react-router-dom';
import FaceLiveness from './FaceLiveness';
import ReferenceImage from './ReferenceImage';


Auth.configure({
    "Auth": {
        "identityPoolId": process.env.REACT_APP_IDENTITYPOOL_ID,
        "region": process.env.REACT_APP_REGION,
        "userPoolId": process.env.REACT_APP_USERPOOL_ID,
        "mandatorySignIn": false,
        "userPoolWebClientId": process.env.REACT_APP_WEBCLIENT_ID
        // "identityPoolId": "us-east-1:70334816-9f51-47c3-b331-8e9aec04ddd9",
        // "region": "us-east-1",
        // "userPoolId": "us-east-1_YGkclllZI",
        // "mandatorySignIn": false,
        // "userPoolWebClientId": "o7f5qcifacde1edfm0ompvrs4"
    }
})

function Liveness() {
    const navigate = useNavigate()
    const [faceLivenessAnalysis, setFaceLivenessAnalysis] = React.useState(null)

    const getfaceLivenessAnalysis = (faceLivenessAnalysis) => {
        if (faceLivenessAnalysis !== null) {
            setFaceLivenessAnalysis(faceLivenessAnalysis)
        }
    }

    const tryagain = () => {
        setFaceLivenessAnalysis(null)
    }
    console.log('faceLivenessAnalysis', faceLivenessAnalysis)

    return (

        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '0 1em'
            }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => navigate('/checkin')}
                    aria-label="close"
                >
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5"
                    sx={{ marginLeft: '-24px' }} >
                    One last step
                </Typography>
                <span></span>
            </Grid>
            <Typography variant="h6">Pass this liveness check so we know you are human</Typography>
            {
                faceLivenessAnalysis && faceLivenessAnalysis.confidence ? (
                    <ReferenceImage faceLivenessAnalysis={faceLivenessAnalysis} tryagain={tryagain}></ReferenceImage>
                ) :
                    (<FaceLiveness faceLivenessAnalysis={getfaceLivenessAnalysis} />)
            }

        </Grid >

    );
}

export default Liveness;
