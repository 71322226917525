export const sampleAppConfig = {
    name: 'My App',
    fullName: 'My App, New Delhi',
    caption: 'Share your details to onboard',
    theme: '#F0851C',
    uploads: [{
        name: 'Aadhaar Card',
        key: 'aadhaar'
    },
    {
        name: 'PAN Card',
        key: 'pan'
    },
    {
        name: 'Driving License',
        key: 'driving-license'
    },
    {
        name: 'Liveness',
        key: 'liveness'
    },
    {
        name: 'Voter ID',
        key: 'voter-id'
    },
    {
        name: 'Passport',
        key: 'passport'
    }],
    documents: [{
        name: 'Bike RC'
    },
    {
        name: 'Car RC'
    },
    {
        name: 'ID Card'
    }],

}