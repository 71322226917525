import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import theme from './Config/theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MAX_WIDTH } from './Utils/enums';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
      <div style={{
        maxWidth: MAX_WIDTH + 'px', width: "100%", margin: 'auto',
        height: '95vh', display: 'flex', flexDirection:'column'
      }}><App /></div>
    </SnackbarProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
