import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CircularProgress, Collapse, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { createWallet, loginCall, otpCall, updateWallet } from '../../Config/api';

export default function Auth() {
  const navigate = useNavigate();
  const one = useRef(null)
  // const one = useRef(null)
  // const one = useRef(null)
  const [phNo, setPhNo] = React.useState('');
  const [name, setName] = React.useState('');
  const [txId, setTxId] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [accountExists, setAccountExists] = React.useState(true);
  const [currentState, setCurrentState] = React.useState('main');
  const [trackFields, setTrackFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingPh, setLoadingPh] = React.useState(false);


  console.log('txId', txId, currentState, trackFields)



  const buttonOne = useCallback(debounce(async (q = phNo) => {
    setLoadingPh(true)
    setTrackFields([])
    loginCall("+91-" + q)
      .then(z => {
        console.log('z', z)
        if (z?.code === 0) {
          setTxId(z?.data?.txId)
          setCurrentState('otp')
          setAccountExists(true)
          setTrackFields(['otp'])
        } else if (z?.code === 105) {
          setCurrentState('otp')
          setTrackFields(['name', 'otp'])

          const id = uuid()
          createWallet(id, "+91-" + q)
            .then(z => {
              console.log(z)
              setTxId(z?.data?.txId)
              if (z) {
                setCurrentState('otp')
                setTrackFields(['name', 'otp'])
                setAccountExists(false)
                setOtp('')
              }
            }).finally((z) => {
              setLoading(false)
            })

        }
      }).finally((z) => {
        setLoading(false)
        setLoadingPh(false)
      })


  }, 1000), []);



  const state = {
    main: {
      buttonText: null,
      disabled: phNo.length <= 10,
      fn: () => buttonOne()
    },
    name: {
      buttonText: 'Continue',
      fn: () => { },
      disabled: name.length <= 0
    },
    otp: {
      buttonText: 'Submit OTP',
      disabled: otp.length <= 5 || (trackFields.includes('name') ? (name.length <= 0) : false),
      fn: () => otpCall(txId, otp)
        .then(async x => {
          if (x) {
            if (trackFields.includes('name')) {
              await updateWallet(name, x.accessToken)
            }
            // window.location.reload()
            // navigate(oauthLink())
          }
        }).finally((z) => {
          setLoading(false)
        }),
    }
  }

  console.log('state', state.otp)
  return (
    <Card variant="outlined" sx={{ padding: '0em 0.6em', margin: '1em', }}>
      <CardContent>
        <Typography variant='h6'>To Confirm your Identity</Typography>
        <Typography variant='body2'>Before uploading your ID Please sign in/up with your Mobile.</Typography>


        <TextField fullWidth autoFocus
          sx={{ margin: '1em 0' }}
          id="Phone Number"
          label="Phone Number"
          value={phNo}
          onChange={(event) => {
            setPhNo(event.target.value);
            if (event.target.value.length >= 10) {
              buttonOne(event.target.value)
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">+91-</InputAdornment>,
            endAdornment: loadingPh ? <InputAdornment position="end"><CircularProgress /></InputAdornment> : null,

          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // one.current.click()
              if (phNo.length >= 10) {
                buttonOne(phNo)
              }
            }
          }}
        />
        <Collapse in={trackFields.includes('name')}>
          <TextField fullWidth autoFocus
            sx={{ margin: '1em 0' }}
            id="Name"
            label="Name"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                one.current.click()
              }
            }}
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">+91-</InputAdornment>,
          // }}
          />

          {/* <LoadingButton trackFields={trackFields === 'Request OTP'}
            ref={one} disabled={!phNo} variant="contained" onClick={() => {

            }}>Request OTP</LoadingButton> */}
        </Collapse>

        <Collapse in={trackFields.includes('otp')}>
          <TextField fullWidth autoFocus
            sx={{ margin: '1em 0' }}
            id="Enter OTP"
            label="Enter OTP"
            value={otp}
            onChange={(event) => {
              setOtp(event.target.value);
            }}
            helperText={(<Grid container direction="row" sx={{ margin: '0', padding: 0 }}>
              <Typography variant="body2" sx={{ margin: '0', padding: 0 }}>Haven’t received OTP?
                <LoadingButton loading={loading === "Resend"}
                  sx={{ width: 'fit-content', fontWeight: 700, fontSize: '0.8rem', margin: '0', padding: 0 }}
                  onClick={() => {
                    // setLoading('Resend')
                    (accountExists ? loginCall("+91-" + phNo) : createWallet(name, "+91-" + phNo))
                      .then(z => {
                        console.log('z', z)
                        if (z?.code === 0) {
                          setTxId(z?.data?.txId)
                          window.snackbar('success', 'OTP sent successfully')

                        }
                      }).finally((z) => {
                        // setLoading(false)
                      })
                  }}
                >Resend</LoadingButton></Typography>
            </Grid>)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                one.current.click()
              }
            }}
          />
        </Collapse>


        {state[currentState].buttonText ? <LoadingButton
          // trackFields={trackFields}
          ref={one}
          disabled={state[currentState].disabled}
          variant="contained"
          onClick={() => { state[currentState].fn() }}>
          {state[currentState].buttonText}
        </LoadingButton> : null}
      </CardContent>
    </Card>
  )
}
