import { CheckCircle, ChevronLeft } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ReactComponent as PoweredByTrential } from '../../Assets/poweredByTrential.svg';
import { ReactComponent as TrentialLogo } from '../../Assets/trential.svg';
import { getImageData, useQuery } from '../../Helper/helpers';
import { MAX_WIDTH } from "../../Utils/enums";
import { sampleAppConfig } from './config';

export default function SampleApp() {
  const navigate = useNavigate();
  const query = useQuery()
  const state = useLocation().state;
  const data2 = state?.data
  const [credInfo, setCredInfo] = useState(null)
  const [selectedCreds, setSelectedCreds] = useState({})
  useEffect(() => {
    if (query.get('payload')) {
      let payload = query.get('payload');
      payload = data2 ?? null
      setCredInfo(payload)
    }
  }, [])
  console.log('cred', selectedCreds)

  return (
    <Grid container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        width: '100%', maxWidth: MAX_WIDTH + 'px', height: '100%',
        flexWrap: 'nowrap'
      }}>

      <Box variant="contained" sx={{
        width: '100%',
        padding: '1em 0.6em 2em',
        borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px',
        backgroundColor: sampleAppConfig.theme,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
      }}>

        <IconButton sx={{ marginRight: 'auto', color: '#ffffff' }}
          onClick={() => {
            window.open('/', '_self')
          }}>
          <ChevronLeft />
        </IconButton>

        <Typography variant="h4" sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}>{sampleAppConfig.name}</Typography>
        <Typography variant="body1" sx={{
          margin: '1em',
          textAlign: 'center',
        }}>{sampleAppConfig.caption}</Typography>
      </Box>

      {
        credInfo ? <Grid container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            padding: '1em',
            paddingBottom: '3em'
          }}><Card variant="outlined" sx={{ width: '100%', padding: '1em', margin: '0.6em 0', borderRadius: '15px', height: 'fit-content' }}>
            <Grid container
              direction="column"
              justifyContent="flex-start"
              alignItems="center">
              <Typography variant="body1" sx={{
                textAlign: 'left',
                width: '100%',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}>Aadhaar details</Typography>
              <Data d={credInfo['aadhaar']} imageBig />
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ width: '100%', padding: '1em', margin: '0.6em 0', borderRadius: '15px', height: 'fit-content' }}>
            <Grid container
              direction="column"
              justifyContent="flex-start"
              alignItems="center">
              <Typography variant="body1" sx={{
                textAlign: 'left',
                width: '100%',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}>Liveness Check</Typography>
              <Data d={credInfo['liveness']} />
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ width: '100%', padding: '1em', margin: '0.6em 0', borderRadius: '15px', height: 'fit-content' }}>
            <Grid container
              direction="column"
              justifyContent="flex-start"
              alignItems="center">
              <Typography variant="body1" sx={{
                textAlign: 'left',
                width: '100%',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}>PAN Card details</Typography>
              <Data d={credInfo['pan']} />
            </Grid>
          </Card>
          <div
            style={{
              padding: '1em 0', position: 'fixed',
              bottom: 0, left: isDesktop ? 'auto' : 0, right: isDesktop ? 'auto' : 0, display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px -4px 12px 0px #0000000D',
              backgroundColor: '#ffffff',
              width: MAX_WIDTH,
            }}>
            <PoweredByTrential />
          </div>
        </Grid> : <><Grid container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            padding: '1em'
          }}>
          <Typography variant="body1" sx={{
            textAlign: 'left',
            width: '100%',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}>Uploads</Typography>
          {
            sampleAppConfig.uploads?.map((x, i) => <Grid
              key={"sample-app-1-" + i}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: '0em 0.6em',
                background: '#EFEFEF',
                border: '1px solid #d5d5d5',
                borderRadius: '8px',
                margin: '0.4em 0',
                width: '100%',
              }}>
              <Typography variant="body1" sx={{
                textAlign: 'left',
                fontSize: '0.8rem',
                fontWeight: 'bold',
              }}>{x.name}</Typography>

              <Checkbox
                sx={{
                  marginLeft: 'auto',
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                }}
                checked={selectedCreds[x.key]}
                onChange={(e) => {
                  setSelectedCreds((qw) => {
                    let z = { ...qw }
                    z[x.key] = e.target.checked;
                    return z;
                  })

                }} /></Grid>)
          }
        </Grid>

          <Button variant="contained" color="primary"
            sx={{
              position: 'fixed', bottom: '20px', left: isDesktop ? 'auto' : 0, right: isDesktop ? 'auto' : 0, width: '96%', margin: 'auto', padding: '1em',
              fontSize: '0.8rem',
            }}
            disabled={Object.keys(selectedCreds).filter(z => selectedCreds[z]).length <= 0}
            onClick={() => {
              const uid = uuid();
              const scope = Object.keys(selectedCreds).reduce((acc, z) => {
                if (selectedCreds[z]) {
                  acc.push('presentation:' + z + ':read')
                }
                return acc;
              }, []).join("+")
              const url = `/oauth/authorize?response_type=code&client_id=test1&scope=${scope}&redirect_uri=`
                + process.env.REACT_APP_REDIRECT_URI + '&state=' + uid
              navigate(url)
            }}>1-Click autofill <TrentialLogo /></Button></>
      }
    </Grid>
  )
}


const Data = ({ d, imageBig = false }) => {
  return <Grid container direction="column" sx={{ margin: '1em 0' }}>
    {Object.keys(d).map((z, i) => <Grid key={z} container
      direction="row" sx={{ margin: z.match(/photo/i) ? 0 : '0.6em 0' }}>
      <Grid item xs={7} sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}>
        <CheckCircle color="success" />
        <Typography variant="body1" sx={{
          textAlign: 'left',
          width: '100%',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          marginLeft: '4px'
        }}>{z}</Typography>
      </Grid>
      <Grid item xs={5} sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}>
        {z.match(/photo/i) ? <img src={getImageData(d[z])} style={{
          width: imageBig ? '60px' : '50px', height: imageBig ? '60px' : '50px',
          margin: '5px', borderRadius: '50%'
        }} alt={z} />
          : <Typography variant="body1" sx={{
            textAlign: 'left',
            width: '100%',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            overflowWrap: 'anywhere'
          }}>{d[z]}</Typography>}

      </Grid>
    </Grid>)}

  </Grid>
}