import { Store } from "pullstate";
const initialState = {
  isAuthenticated: false,
  authRes: {},
  currentUserData: {},
  selectedOffers: {},
  docData: {},
  credentials: [],
  digilockerCredentials: [],
  liveness: {},
  selectedCredentialAttributes: [],
  trentialId: null,
  credentialLivenessCheckRecord: {
    Aadhaar: false,
    'Driving License': false,
    Passport: false,
    'Voter ID Card': false,
    'Voter ID': false
  },
  searchedQuery:''
}
export const UIStore = new Store(initialState);

UIStore.subscribe(
  (s) => s,
  (userStore) => {
    localStorage.setItem("store", JSON.stringify(userStore));
  }
);

export const rehydrateStore = async () => {
  try {
    const user = localStorage.getItem("store");
    if (user !== null) {
      const data = JSON.parse(user);
      UIStore.replace({ ...data });
    }
  } catch (e) {
    console.log("Error occured");
  }
};


export const updateStore = (key, value) => {
  UIStore.update((s) => {
    s[key] = value;
  });
};

export const fetchFromStore = (key) => {
  const mainStore = UIStore.getRawState();
  return mainStore[key];
};

export const logout = () => {
  UIStore.update((s) => ({
    ...initialState
  }));
  localStorage.setItem('token', '')
}

export const login = (info) => {
  UIStore.update((s) => {
    s.isAuthenticated = true;
    s.authRes = info
    // s.currentUserData = {};
    s.selectedOffers = {}
  });
  localStorage.setItem('token', info.accessToken)

}

