export const routes = {
    login: '/api/1.0/profile/login',
    refreshToken: '/api/1.0/profile/login/get-token',
    profile: '/api/1.0/profile',
    credentials: '/api/1.0/credentials',
    otp: (txId) => `/api/1.0/profile/otp/${txId}/verify`,
    uploadDocGetAttributes: (type) => `/api/1.0/credentials/documents/${type}`,
    putVcInWallet: (type) => `/api/1.0/credentials/documents/${type}/receive`,
    getAadhaar: '/api/1.0/credentials/digilocker/aadhaar',
    putVcInWalletFromDigilocker: (uri) => `/api/1.0/credentials/digilocker/${uri}`,
    getDigilockerToken: '/api/1.0/digilocker/get-token',
    getIssuedDocsFromDigilocker: '/api/1.0/digilocker/proxy/oauth2/2/files/issued',
    pullDocumentInDigilocker: '/oauth2/1/pull/pulldocument',
    getCredentialInvitation: '/api/2.0/credential-batch',
    oobInvitation: '/api/1.0/oob/receive',
    aadharCall: '/api/1.0/credential/ekyc/aadhaar',
    aadhaarOtpCall: '/api/1.0/credential/ekyc/aadhaar/verify',
    masterDigilocker: '/api/1.0/credential/digilocker',
    oAuth: (responseType = "code", clientId = "test1", redirectUri, scope, state) => '/api/1.0/oauth/authorize?response_type=' +
        responseType + '&client_id=' + clientId + '&scope=' + scope + '&state=' + state + '&redirect_uri=' + redirectUri

}